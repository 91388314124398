<template>
  <div class="team_content_container w">
    <div class="team_member_item" v-for="item in memberList" :key="item.id">
      <TeamMember
        :member_1="item.member_1"
        :member_2="item.member_2"
      ></TeamMember>
    </div>
  </div>
</template>

<script>
import TeamMember from './Team-member.vue'
export default {
  name: 'TeamContent',
  components: {
    TeamMember
  },
  data() {
    return {
      memberList: [
        {
          id: 1,
          member_1: require('@/assets/images/about/member1_1.png'),
          member_2: require('@/assets/images/about/member1_2.png')
        },
        {
          id: 2,
          member_1: require('@/assets/images/about/member2_1.png'),
          member_2: require('@/assets/images/about/member2_2.png')
        },
        {
          id: 3,
          member_1: require('@/assets/images/about/member3_1.png'),
          member_2: require('@/assets/images/about/member3_2.png')
        },
        {
          id: 4,
          member_1: require('@/assets/images/about/member4_1.png'),
          member_2: require('@/assets/images/about/member4_2.png')
        },
        {
          id: 5,
          member_1: require('@/assets/images/about/member5_1.png'),
          member_2: require('@/assets/images/about/member5_2.png')
        },
        {
          id: 6,
          member_1: require('@/assets/images/about/member6_1.png'),
          member_2: require('@/assets/images/about/member6_2.png')
        },
        {
          id: 7,
          member_1: require('@/assets/images/about/member7_1.png'),
          member_2: require('@/assets/images/about/member7_2.png')
        },
        {
          id: 8,
          member_1: require('@/assets/images/about/member8_1.png'),
          member_2: require('@/assets/images/about/member8_2.png')
        },
        {
          id: 9,
          member_1: require('@/assets/images/about/member9_1.png'),
          member_2: require('@/assets/images/about/member9_2.png')
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.team_content_container {
  padding: 133px 181px 0;
  display: flex;
  flex-wrap: wrap;
}
</style>
