<template>
  <div class="time_member_container">
    <div class="item">
      <transition>
        <div class="small">
          <img :src="member_1" alt="" />
        </div>
      </transition>
      <transition>
        <div class="big">
          <img :src="member_2" alt="" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeMember',
  props: {
    member_1: {
      type: String,
      required: true
    },
    member_2: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.time_member_container {
  margin-bottom: 134px;
}
img {
  display: block;
}
.item {
  width: 513px;
  height: 529px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  &:hover {
    .big {
      display: block;
    }
    .small {
      display: none;
      opacity: 0;
    }
  }
  .small {
    width: 359px;
    height: 529px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .big {
    width: 517px;
    height: 529px;
    display: none;
    animation: move 0.3s;
  }
}

@keyframes move {
  0% {
    transform: translateX(-265px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
