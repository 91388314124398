<template>
  <div class="team-container">
    <!-- 顶部导航占位符 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 公司团队标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 公司团队内容 -->
    <TeamContent></TeamContent>
  </div>
</template>

<script>
import TeamContent from './components/Team-content.vue'
export default {
  name: 'AboutTeam',
  components: {
    TeamContent
  },
  data() {
    return {
      title: '泉邦案例',
      complement: '饮水思泉 立业兴邦'
    }
  }
}
</script>

<style lang="less" scoped></style>
