import { render, staticRenderFns } from "./Team-member.vue?vue&type=template&id=fe607e24&scoped=true&"
import script from "./Team-member.vue?vue&type=script&lang=js&"
export * from "./Team-member.vue?vue&type=script&lang=js&"
import style0 from "./Team-member.vue?vue&type=style&index=0&id=fe607e24&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe607e24",
  null
  
)

export default component.exports